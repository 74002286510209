body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif;
  line-height: 1.6;
  font-size: 16px;
  font-weight: 500;
}

/* @media (min-width: 1600px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1500px;
  }
} */

.navbar-toggler:hover,
.navbar-toggler {
  background-color: #000;
}
ul {
  padding-left: 0;
}
.navbar-nav .nav-link.active,
.navbar-nav .show>.nav-link {
  color:#000;
}

.navbar-nav .nav-link:hover {
  background-color: #e9ecef;
  color:#1329e9 !important;
  border-radius: 6px;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.head-section .nav-link {
  padding: 5px 24px !important;
}

.navbar-toggler,
.navbar-toggler:hover {
  background-color: #2236e5;
}

.nav-link.dropdown-toggle::after {
  display: none !important; /* Hide the arrow */
}

.nav-link.dropdown-toggle:hover{
  display: flex;
  
}

.dropdown-menu {
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.dropdown:hover .dropdown-menu{
  display: block;
}

.dropdown-item {
  padding: 10px;
  padding-left: 24px;
  padding-right: 24px;
}

.dropdown-item:hover {
  color: #F3F3F3;
  background-color: #1329e9;
}

.navbar-toggler-icon {
  filter: invert(1);
}

.row {
  align-items: center;
}

h1 {
  font-weight: 700;
}

section:not(.head-section) {
  padding: 144px 0;
}
section.consulting-section {
  padding: 0 0 120px 0 !important;
}
.servicesection {
  padding: 0 !important;
  background-image: url('./bgimages/bg_ser_pro.jpg') ;
}

.btn,
.btn:hover {
  background-color: #2236E5;
  color: #fff;
  outline: none;
  box-shadow: none;
}

.home-section {
  background-color: #F3F3F3;
}

.diferrence-section {
  position: relative;
  color: #fff;
}

.diferrence-section::before {
  content: '';
  background-image: url('./bgimages/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  /* Ensure the pseudo-element is behind the content */
  background-color: #2236E5;
}

.pink-bar {
  background-color: #FF1D62;
  height: 8px;
  width: 120px;
  margin: auto;
}

.icon-box {
  border-radius: 8px;
  margin: auto 18px;
  padding: 28px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.saplogo {
  width: 100px;
  height: 100px;
}

.sap-user-section {
  background-image: url('./bgimages/sap-user.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.accordion-item {
  background-color: #fff;
  padding: 8px 12px;
  border: none;
  box-shadow: 0 0 10px 4px #9f9f9f4a;
  margin-bottom: 24px;
  border-radius: 0 !important;
  cursor: pointer;

}

.accordion-content {
  transition: 2s;
}

.fa-arrow-right,
.fa-arrow-up {
  color: #FF1D62;
}

.react-multiple-carousel__arrow {
  background-color: #2236E5 !important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
}

.react-multiple-carousel__arrow--right {
  right: 0 !important;
}

.height {
  height: 250px;
}

.accordion {
  background: linear-gradient(90deg, #1329e9 50%, rgba(39, 37, 37, 0) 50%);
  padding: 30px;
}
.contactbtn a {
  text-decoration: none;
}
@media (max-width: 991px) {
  ul.two-column li {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .height-animated {
    height: 100% !important;
    padding: 24px 0 !important;
  }
  ul.two-column {
    flex-direction: column;
  } 
 h1 {
    font-size: 36px;
    line-height: inherit;
  }
  .carousel-item-padding-40-px {
    margin-right: 0 !important;
  }

  footer {
    padding: 2rem 0 0 0 !important;
  }

  .add-img-1 {
    margin-top: 45px;
  }

  .footer-08 .footer-heading {
    margin-bottom: 9px !important;
  }

  section:not(.head-section) {
    padding: 60px 0;
  }

  .height {
    height: 0;
    margin-top: 32px;
  }

  .col-md-6,
  .col-md-4,
  .col-md-3,
  .col-md-8 {
    width: 100%;
  }

  .mb-md-0 {
    padding-left: 65px !important;
  }

  .copy-right-section {
    margin-top: 20px !important;
  }
}

.service-box-single-item {
  padding: 70px;
}

.service-box-single-item .title {
  margin-bottom: 36px;
  margin-top: 24px;

}

.service-box-single-item {
  position: relative;
  background: #f4f5ff;
  padding: 45px;
  overflow: hidden;
}

.mb-6 {
  margin-bottom: 25px;
}

.icon img {
  background-color: #1329e9;
  padding: 14px;
  width: 62px;
  border-radius: 6px;

}

.service-box-single-item .list-item li {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 15px;
}

.service-box-single-item .list-item li .fa {
  position: relative;
  display: block;
}

.list-item {
  padding: 0;
}

.custom-button-group button {
  background-color: #2236E5 !important;
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
}

.custom-button-group button .fa {
  color: #fff !important;
}

.custom-button-group {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.carousel-item-active {
  background-color: #F3F3F3 !important;
  padding: 24px;
  border-radius: 14px;
}

.react-multi-carousel-track {
  padding: 28px 0 !important;
}

footer {
  padding: 7em 0 0 0;
}

.footer-08 .container {
  padding: 0;
  overflow: hidden;
  box-shadow: rgb(161 161 249 / 5%) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
  border-radius: 24px;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.footer-08 a {
  color: #1329e9;
  text-decoration: none;
}

/* .footer-08 p {
  color: rgba(0, 0, 0, 0.3);
} */

.footer-08 .footer-heading {
  font-size: 18px;
  margin-bottom: 30px;
  color: #022054;

}

.footer-08 .footer-heading.footer-heading-white {
  background-color: #2236E5;
  padding: 12px 24px;
}

@media (min-width: 768px) {
  .footer-08 .footer-heading.footer-heading-white {
    color: #fff;
  }
}

.footer-08 ul.list-unstyled li a {
  color: rgb(73 73 73);
}

.footer-08 .contact-form {
  width: 100%;
}

.footer-08 .contact-form .form-control {
  height: 50px;
  background: #fff;
  color: rgba(0, 0, 0, 0.3);
  font-size: 14px;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  margin-bottom: 14px;
}

.footer-08 .contact-form .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(0, 0, 0, 0.3) !important;
}

.footer-08 .contact-form .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(0, 0, 0, 0.3) !important;
}

.footer-08 .contact-form .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(0, 0, 0, 0.3) !important;
}

.footer-08 .contact-form .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(0, 0, 0, 0.3) !important;
}

.footer-08 .contact-form .form-control:focus {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.footer-08 .contact-form .form-control:focus,
.footer-08 .contact-form .form-control:active {
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.footer-08 .contact-form .submit {
  background: #2f89fc !important;
  color: #fff;
}

.footer-08 .contact-form textarea.form-control {
  height: inherit !important;
}

@media (max-width: 767.98px) {
  .footer-08 .aside-stretch-right {
    background: transparent;
  }

  .footer-08 .aside-stretch-right:after {
    background: transparent;
    display: none;
  }
}

.input-group .input-group-text,
.input-group .form-control {
  border: none;
  border-radius: 24px;
  font-size: 14px;
  cursor: pointer;
}

.input-group .input-group-text {
  background-color: #3043E6;
  color: #fff;
  padding: 6px 20px;
}

.input-group {
  border: 1px solid #1329e9;
  border-radius: 18px;
  padding: 5px 6px;
  margin-bottom: 28px;
}

.lets-talk {
  max-width: 90%;
  width: 100%;
  margin: auto;
}

.copy-right-section {
  background: #2236e5;
  padding: 50px 0 16px 0;
  text-align: center;
  color: #fff;
  margin-top: -65px;
}

.card-body {
  padding: 0 0;

}

.card-title {
  margin-bottom: 16px;
  margin-top: 14px;
}

.dev-card {
  background: #1329e90f;
  padding: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}

.card-title a {
  text-decoration: none;
}

.learnmore i {
  font-size: 28px;
  font-weight: 500;
}

.custom-head-section {
  background-image: url('./bgimages/custom-software-development-services.webp');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.webdevelopment-section {
  background-image: url('./bgimages/Web_develop.jpeg');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.testing-section {
  background-image: url('./bgimages/testing-services.webp');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.appdev-section {
  background-image: url('./bgimages/mobile-app-development-services.webp');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.product-section {
  background-image: url('./bgimages/product-development-service.webp');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.team-section {
  background-image: url('./bgimages/dedicated-services.webp');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-section {
  background-image: url('./bgimages/banner_Aboutus_1.webp');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-section {
  background-image: url('./bgimages/contact-banner.webp');
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-head-section::before,
.webdevelopment-section::before,
.testing-section::before,
.appdev-section::before,
.product-section::before,
.team-section::before,
.about-section:before,
.contact-section::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #0000007d;
  top: 0;
}

.banner-content-wrapper {
  position: relative;
  z-index: 1;
  max-width: 1080px;
  margin: auto;
}

.contact-form-wrapper .form-wrapper {
  max-width: 77rem;
  margin: 0 auto;
  box-shadow: 0 0 60px rgba(11, 46, 88, .5);
  background-color: #fff;
  padding: 3.6rem 4.8rem 4.8rem;

}
.contact-form-wrapper .form-wrapper .form-control {
  height: 45px;
  display: flex;
  align-items: center;
}

.contact-form .form-group {
  margin-top: 18px;
}

.contact-form .form-control {
  height: 50px;
}

.contact-section .col-md-6 {
  position: relative;
  z-index: 1
}

.error {
  font-size: 14px;
  color: red;
}
.react-multi-carousel-list {
  margin-top: 56px !important;
}
-app.tech-bg {
  background-image: url('./bgimages/bg_home.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
-app.tech-bg-cu {
  background-image: url('./bgimages/bg_home.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.contactbtn,.contactbtn:hover,.contactbtn:focus,.contactbtn:active {
  background-color: #fff !important;
  color: #1329e9 !important;
  text-transform: uppercase;
  padding: 11px 18px;
  margin-top: 24px;
}

.card-text {
  display: -bbkit-box;
   -webkit-line-clamp: 4;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-overflow: ellipsis;
   transition: 1s ease-in;
}
.more-content {
  display: block;
  color: #1329e9;
  font-size: 14px;
  position: relative;
  top: 12px;
}
.dev-card:hover .card-text {
  display: block;
}
.dev-card:hover .more-content {
  display: none;
}
.work-grid-wrapper .row {
  align-items: start ;
}
.related-category li {
  padding: .3rem 1rem;
  color: #fff;
  line-height: 1.9rem;
  border: 1px solid #fff;
  margin: 0;
}
.related-category {
  display: flex;
  margin: 1.1rem 0 2.3rem;
  gap: 14px;
  padding-left: 0;
}
ul li {
 list-style: none;
}
.technology-stack-grid-new .grid-item .grid-inner h3 span {
  position: relative;
}
.technology-stack-grid-new .grid-item .grid-inner h3 span:before {
  position: absolute;
    left: 50%;
    width: 1.1rem;
    height: 1.1rem;
    background-color: #ff1d62;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    bottom: -47px;
    content: " ";
    z-index: 3;
    transform: translateX(-50%);
}
.technology-stack-grid-new {
  margin: 0 -1.5rem;
}
.technology-stack-grid-new .grid-item {

  padding: 0 1.5rem;
  margin-bottom: 56px;
}
.technology-stack-grid-new .grid-item .grid-inner {
  background: #f5f6ff;
  padding: 0;
  height: 100%;
  transition: .5s;
}
.technology-stack-grid-new .grid-item .grid-inner h3 {
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 28px;
  color: #0b2e58;
  font-weight: 400;
  padding: 1rem 3rem;
  position: relative;
  border-bottom: .3rem solid #ff1d62;
  min-height: 7.1rem;
  max-height: 7.1rem;
}
.technology-stack-grid-new .grid-item .grid-inner .tech-stack-list-wrapper {
  padding: 2rem 3rem;
  display: flex;
  margin: 0 -.5rem -1.6rem;
}
.technology-stack-grid-new .grid-item .grid-inner ul li {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  color: #333;
  margin-bottom: 16px;
  padding-left: 3rem;
  word-break: break-word;
}
.technology-stack-grid-new .grid-item .grid-inner ul li::before {
  position: absolute;
  content: "";
  left: 1rem;
  height: 2rem;
  width: 2rem;
  background-image: url('./bgimages/tech-stack-bullet.svg');
  top: .1rem;
  background-repeat: no-repeat;
}
.technology-stack-grid-new .grid-item .grid-inner h3 span {
  margin-right: 1.7rem;
  display: flex;
  position: relative;
  min-height: 51px;
  align-items: center;
}
.risepro-cta-block {
  background-color: #2236e5;
  color: #fff;
}
.risepro-tools-technology-item-inner {
  background-color: #fff;
  color: #000;
  padding: 24px 32px;
}
.faq-title h5 {
  color: #555555;
}
.faq-inner {
  padding: 16px 0;
  border-bottom: 1px solid #dadfe3;
  margin-bottom: 12px;
}
/* .faq-inner :last-child {
  border: none ;
} */
.faq-detail {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out, padding 0.4s ease-out;
  padding: 0 1rem;
}

.faq-detail p {
  margin: 0;
}

.faq-detail.active {
  max-height: 500px; /* adjust according to your content size */
  padding: 1rem;
}
.custom-contactbtn {
  background-color: #1329e9 !important;
  color: #fff !important;
  text-transform: uppercase;
  padding: 11px 18px;
  margin-top: 24px;

}
.custom-contactbtn a {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #fff;
  text-decoration: none;
}
.custom-contactbtn .fa {
  background-color: #fff;
  color: #1329e9;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: grid;
  place-items: center;
}
.content-block h2 {
  position: relative;
  max-width: 700px;
  padding-left: 30px;
}
.content-block h2:before {
  position: absolute;
  content: "";
  width: 5px;
  top: -4px;
  bottom: -8px;
  left: 0;
  background-color: #1329e9;
}
.faq-detail ul li{
  list-style: auto;
}
.faq-detail ul {
  padding-left: 14px;
  margin-top: 14px;
}

tbody, td, tfoot, th, thead, tr {
  border-color: #dadfe3;
  border-style: solid;
  border-width: 1px;
  padding: 4px 16px;
}

.industry-banner-color{
  color: #e9ecef; /* for finance section*/
}

.inner-page-description{
  border-bottom: 1px solid #eee;
  padding: 4rem 0;
  padding-left: 20px;
  padding-right: 20px;
}
.industry-expertise-fin{
  border-bottom: 1px solid #eee;
}
.consumer-bottom-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns of equal width */
  gap: 20px; /* Space between items */
  padding: 20px;
}
.forex-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px; /* Space between items */
  padding: 20px;
}
.pension-list{
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns of equal width */
  gap: 20px; /* Space between items */
  padding: 20px;
}
.pension-2-list{
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 3 columns of equal width */
  gap: 20px; /* Space between items */
  padding: 20px;
  padding-right: 150px;
  padding-left: 150px;
}
.industry-head-line{
  position: relative;
    max-width: 360px;
}
.industry-head-line::after {
  content: '';
  display: block;
  background: #4658fa;
  height: 4px;
  width: 102px;
  bottom: -4px;
  position: relative;
}
.blue_tick{
  height: 25px;
  width: 25px;
  margin-right: 15px;
}

.inner-page-listing {
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
}
.expertise-items{
  padding: auto;
}
.fin-background{
  background-image: url("./bgimages/sap-user.png");
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.background-transparent{
  background-color: rgba(0, 0, 0, 0);
}
.indusry-page-description{
  border-bottom: 1px solid #eee;
  padding-left: 20px;
  padding-right: 20px;
}
.corporate-background{
  background-image: url("./bgimages/sap-user.png");
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.home-introduction {
  background-image: url('./bgimages/homebg.jpeg');
  background-position: initial;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;

}
.home-introduction::after{
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffd1;
}
.heading-color {
  color: #ffb11b;
}
.welcome-text {
  font-size: clamp(1rem, 6.5vw, 8rem);
  line-height: initial;
  position: relative;
  z-index: 1;
}
.sticky-header {
  box-shadow: rgba(0, 0, 0, 0.04) 1px 0px 8px;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background: #ffffffe8;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}
.energy-bgimg {
  background-image: url('./bgimages/banner-energy-utilities.webp') !important;
}
ul.four-column, ul.single-column, ul.three-column, ul.two-column {
  display: flex
;
  flex-wrap: wrap;
  box-sizing: border-box;
}
ul.two-column li {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 0 0 1.8rem;
  position: relative;
}

ul.inner-page-listing li:before {
  content: "";
  position: absolute;
  background-image: url('./bgimages/blue-tick.svg');
  width: 20px;
  height: 20px;
  left: 0;
  right: auto;
  top: 4px;
  background-repeat: no-repeat;
}

.energy-bgimg2 {
  background-image: url('./bgimages/street-light-gray-bg.webp') !important;
  background-repeat: no-repeat;

}

.footer-item {
  font-size: 16px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  padding: 5px 0;
}

.footer-item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #007bff; /* Color of the underline */
  transition: width 0.3s ease-in-out;
}

.footer-item:hover::after {
  width: 60%;
}

.aboutus{
  background-image:  url('./bgimages/bg_aboutus.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
  height: 100vh;
}

.top-bg {
  background-image: url('./bgimages/tophome.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.energy-bgimg {
  background-image: url('./bgimages/banner-energy-utilities.webp') !important;
}

ul.four-column,
ul.single-column,
ul.three-column,
ul.two-column {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

ul.two-column li {
  max-width: 50%;
  flex: 0 0 50%;
  padding: 0 0 0 1.8rem;
  position: relative;
}

ul.four-column li {
  max-width: 25%;
  flex: 0 0 25%;
  padding: 0 0 0 1.8rem;
  position: relative;
}

ul.inner-page-listing li:before {
  content: "";
  position: absolute;
  background-image: url('./bgimages/blue-tick.svg');
  width: 20px;
  height: 20px;
  left: 0;
  right: auto;
  top: 4px;
  background-repeat: no-repeat;
}

.energy-bgimg2 {
  background-image: url('./bgimages/street-light-gray-bg.webp') !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.media-bgimg {
  background-image: url('./bgimages/banner-media-entertainment-software-solutions.webp') !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.media-bgimg2 {
  background-image: url('./bgimages/industry-digital-signage-bg.webp') !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.iotsection {
  background-image: url('./bgimages/healthcare-app-background.webp') !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.oilgas-bgimg {
  background-image: url('./bgimages/banner-oil-gas-mining-software-solutions.webp') !important;
  background-repeat: no-repeat;
  background-size: cover;
}

.checklogo li {
  position: relative;
}

.checklogo li {
  padding-left: 36px;
}

.footer-item {
  font-size: 16px;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  padding: 5px 0;
}

.footer-item::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #007bff;
  /* Color of the underline */
  transition: width 0.3s ease-in-out;
}

.footer-item:hover::after {
  width: 60%;
}
.content-wrapper {
  position: relative;
  padding-left: 45px;
}

.content-wrapper::before {
  position: absolute;
  content: "";
  left: 0;
  top: .3rem;
  background-image: url('./bgimages/circle-checked-blue.svg');
  width: 3rem;
  height: 3rem;
  background-repeat: no-repeat;
  background-size: 30px;
}

.value-listing {
  border-top: 1px solid #dadfe3;
  padding-top: 36px;
  margin-top: 36px;
}

.techstack-title {
  padding: 1.42rem 2rem;
  border: .1rem solid #c6d2de;
  color: #0b2e58;
  line-height: 1.3;
  display: flex;
  align-items: center;
  background-color: #fff;
  z-index: 9;
  position: relative;
}

.techstack-title::after {
  position: absolute;
  right: -26px;
  top: 50%;
  width: 6.8rem;
  height: 1.2rem;
  content: "";
  background-size: cover;
  background-image: url('./bgimages/dotted-arrow.svg') !important;
  transform: translate(0, -50%) rotate(180deg);
  z-index: -1;

}


.new-repro-tech-item {
  display: flex;
  flex-wrap: wrap;
}

.techstack-right ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0;
}

.techstack-right ul li {
  background-color: #f6f7f8;
  border: .1rem solid #e1e1e1;
  padding: 2.02rem 2.36rem;
  color: #212121;
  margin: 0 2rem 2rem 0;
  line-height: 1;
}

.card {
  padding: 24px;
}

.risepro-bullet-list li {
  position: relative;
  text-align: left;
  padding-left: 14px;
}

.risepro-bullet-list li:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 0;
  background-color: #1329e9;
  height: 5px;
  width: 5px;
}

.global-section {
  background-image: url('./bgimages/global-presence-map-3.webp');
  background-size: inherit;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.activated {
  border-bottom: 3px solid #2236E5 !important;
}

.hiring-tabs {
  padding: 8px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  font-size: 20px;
}

.industry-links li a,
.industry-links li a .fa {
  color: #5f5f5f !important;
  text-decoration: none;
  font-size: 20px;
}


.process-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -23px -46px;
  justify-content: center
}

.process-wrapper .process-outer {
  flex: 0 0 30%;
  max-width: 30%;
  padding: 0 23px;
  margin-bottom: 46px;
}

.heading-part {
  display: flex;
  align-items: center
}

.heading-part span {
  display: inline-block;
  padding: 2px;
  border: 2px solid #5598ea;
  background-color: #f8f8f8;
  border-radius: 50%;
  position: relative
}

.heading-part span::after {
  position: absolute;
  content: "";
  left: 50%;
  transform: translateY(-50%);
  top: 148px;
  width: 2px;
  height: 180px;
  background-color: #5598ea;
}

.heading-part span em {
  font-style: normal;
  font-size: 26px;
  line-height: 1.15;
  letter-spacing: -.65px;
  color: #fff;
  background-color: #5598ea;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative
}

.heading-part span em::after {
  position: absolute;
  content: "";
  left: 43%;
  transform: translateY(-50%);
  top: 241px;
  width: 10px;
  height: 10px;
  background-color: #5598ea;
  border-radius: 50%
}

.heading-part.green-box span {
  border-color: #64b02a !important
}

.heading-part.green-box span em,
.heading-part.green-box span em::after,
.heading-part.green-box span:after {
  background-color: #64b02a !important
}

.heading-part.darkgreen-box span {
  border-color: #65b02a !important
}

.heading-part.darkgreen-box span em,
.heading-part.darkgreen-box span em::after,
.heading-part.darkgreen-box span::after {
  background-color: #65b02a !important
}

.heading-part.purple-box span {
  border-color: #f282d9 !important
}

.heading-part.purple-box span em,
.heading-part.purple-box span em::after,
.heading-part.purple-box span::after {
  background-color: #f282d9 !important
}

.heading-part.orange-box span {
  border-color: #ff9300 !important
}

.heading-part.orange-box span em,
.heading-part.orange-box span em::after,
.heading-part.orange-box span::after {
  background-color: #ff9300 !important
}

.heading-part.lightorange-box span {
  border-color: #e67028 !important
}

.heading-part.lightorange-box span em,
.heading-part.lightorange-box span em::after,
.heading-part.lightorange-box span::after {
  background-color: #e67028 !important
}

.heading-part.blue-box span {
  border-color: #6360ce !important
}

.heading-part.blue-box span em,
.heading-part.blue-box span em:after,
.heading-part.blue-box span::after {
  background-color: #6360ce !important
}

.heading-part.light-green-box span {
  border-color: #c9b637 !important
}

.heading-part.light-green-box span em,
.heading-part.light-green-box span em::after,
.heading-part.light-green-box span::after {
  background-color: #c9b637 !important
}

.heading-part.violet-box span {
  border-color: #a64482 !important
}

.heading-part.violet-box span em,
.heading-part.violet-box span em::after,
.heading-part.violet-box span::after {
  background-color: #a64482 !important
}

.heading-part.lightviolet-box span {
  border-color: #8046a9 !important
}

.heading-part.lightviolet-box span em,
.heading-part.lightviolet-box span em::after,
.heading-part.lightviolet-box span::after {
  background-color: #8046a9 !important
}

.heading-part h3 {
  padding-left: 15px;
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.16;
  color: #0b2e58
}

.process-inner p {
  line-height: 1.625;
  padding-left: 77px
}

@media (min-width:768px) and (max-width: 1199px) {
  .process-wrapper .process-outer {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 40px;
  }
}

@media (min-width:0) and (max-width: 767px) {
  .process-wrapper .process-outer {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 30px;
    padding: 0;
  }

  .heading-part span::after {
    top: 93px;
    height: 90px;
  }

  .heading-part span em::after {
    top: 141px;
  }
}

.img-background {
  position: relative;
}
.img-background::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #0000007a;
  top: 0;
  left: 0;
}
.container.text-white {
  position: relative;
  z-index: 9;
}
.checkbox {
  position: relative;
  padding-left: 36px;
  max-width: 500px;
  width: 100%;
}
.checkbox::before {
  content: "";
  background-image: url('./bgimages/circle-checked-blue.svg');
  position: absolute;
  left: -20px;
  top: 0;
  height: 30px;
  width: 30px;
  background-repeat: no-repeat;
  background-size: contain;
}

.bulletpoints-grid {
  display: grid;
    gap: 3px;
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
}
.contact-sharing-section h2{
  position: relative;
  padding-left: 28px;
}
.contact-sharing-section h2::before{
  position: absolute;
  content: "";
  width: 5px;
  top: -7px;
  bottom: -8px;
  left: 0;
  background-color: #2236e5;
}
.contact-fa {
  background-color: #1329e9;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.border-yellow {
  border-left: 5px solid #1329e9 !important;
  padding-left: 16px;
}



.border-btm-line-animate {
  width: 100px;
  height: 2px;
  background: transparent;
  margin-left: 51px;
  transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
  .img-bg-box-content1 {
    position: absolute;
    left: 50px;
    top: 10%;
    width: 45%;
  }
}

.border-btm-line-animate::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50px;
  width: 100%;
  height: 100%;
  background: #7D7D7D;
  animation: animate-border 5s linear infinite;
}

@keyframes animate-border {
  0% {
    left: 0;
  }

  50% {
    left: 100%;
  }

  0% {
    left: 0;
  }
}
.height-animated {
  height: 430px;
}
@media(min-width:992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 0);
  }
  .img-bg-main-box {
    position: relative;
    overflow: hidden;
  }

  .img-bg-box6 {
    padding-top: 30%;
    padding-bottom: 5%;
    background: url("./bgimages/SAP-Upgrade-Services.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s ease;
  }

  .img-bg-box6:hover {
    transform: scale(1.1, 1.1);
    filter: grayscale(1);
  }

  .img-bg-box-content6 {
    position: absolute;
    right: 50px;
    top: 10%;
    width: 45%;
  }

  .img-bg-box5 {
    padding-top: 30%;
    padding-bottom: 76px;
    background: url("./bgimages/SAP-BASIS-Services.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s ease;
  }

  .img-bg-box5:hover {
    transform: scale(1.1, 1.1);
    filter: grayscale(1);
  }

  .img-bg-box-content5 {
    position: absolute;
    left: 50px;
    top: 10%;
    width: 45%;
  }

  .img-bg-box4 {
    padding-top: 30%;
    padding-bottom: 5%;
    background: url("./bgimages/SAP-Migration-Services.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s ease;
  }

  .img-bg-box4:hover {
    transform: scale(1.1, 1.1);
    filter: grayscale(1);
  }

  .img-bg-box-content4 {
    position: absolute;
    right: 50px;
    top: 10%;
    width: 45%;
  }

  .img-bg-box3 {
    padding-top: 30%;
    padding-bottom: 30px;
    background: url("./bgimages/SAP-Implementation-Services.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s ease;
  }

  .img-bg-box3:hover {
    transform: scale(1.1, 1.1);
    filter: hue-rotate(45deg);
  }

  .img-bg-box-content3 {
    position: absolute;
    left: 50px;
    top: 20%;
    width: 45%;
  }

  .img-bg-box2 {
    padding-top: 30%;
    padding-bottom: 5%;
    background: url("./bgimages/SAP-Application-Management-Support-AMS-Services.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s ease;
  }

  .img-bg-box2:hover {
    transform: scale(1.1, 1.1);
    filter: hue-rotate(45deg);
  }

  .img-bg-box-content2 {
    position: absolute;
    right: 50px;
    top: 10%;
    width: 45%;
  }

  .img-bg-box1 {
    padding-top: 30%;
    padding-bottom: 5%;
    background: url("./bgimages/SAP-Service-Offerings.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 1s ease;
  }

  .img-bg-box1:hover {
    transform: scale(1.1, 1.1);
    filter: grayscale(1);
  }

  .img-bg-box-content1 {
    position: absolute;
    left: 50px;
    top: 10%;
    width: 45%;
  }


}